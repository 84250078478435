<template>
  <div class="bc-specialists">
    <div class="component component__padding page">
      <div class="component__title">
        {{ $t('SPECIALISTS.TITLE') }}
      </div>
      <div class="component__subtitle">
        <span v-if="error">
          {{ $t('SPECIALISTS.EMPTY') }}
        </span>
        <span v-else>
          {{ $t('SPECIALISTS.SUBTITLE') }}
        </span>

      </div>

      <div class="bc-specialists__content">
        <bc-card
            class="bc-specialists__content__card"
            v-for="specialist in specialistsList" :key="specialist.id"
            :card-type="'specialist'"
            :img="specialist.picture"
            :title="specialist.name"
            :subtitle="specialist.position">
        </bc-card>
      </div>

    </div>


  </div>
</template>

<script>
// import {usePageTitle} from "@/utils";
import {computed} from "vue";
import {store} from "@/store";
import {useHead} from "@vueuse/head";

export default {
  name: "bc-specialists",
  created() {
    useHead({
      // Can be static or computed
      title: computed(() => store.getters.getSeoDataForPage('SPECIALISTS')?.title),
      meta: [
        {
          name: `description`,
          content: computed(() => store.getters.getSeoDataForPage('SPECIALISTS')?.description),
        },
      ],
    })
    // usePageTitle(
    //     computed(() => store.getters.getSeoDataForPage('SPECIALISTS')?.title),
    //     computed(() => store.getters.getSeoDataForPage('SPECIALISTS')?.description))
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http.get('/api/specialists/').then(resp => {
        this.specialistsList = resp.data
        if (!this.specialistsList.length) {
          this.error = true
        }
      }).catch(err => {
        this.error = true
        console.error(err)
      })
    },
  },
  data() {
    return {
      specialistsList: [],
      error: false
    }
  }
}
</script>

<style scoped lang="scss">
.bc-specialists {
  min-height: 100vh;
  .component__subtitle {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;
    padding: 0 20px;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 100px;

    &__card {
      max-width: 30%;
      &:nth-child(2) {
        margin-top: 70px;
      }
      &:not(:nth-last-child(-n+3)) {
        margin-bottom: 100px;
      }

    }

    &__empty {
      display: flex;
      margin: auto;
      align-items: center;
      min-height: 600px;
    }
  }
}



@media screen and (max-width: 900px){
  .bc-specialists {
    &__content {
      flex-wrap: wrap;
      justify-content: space-around;

      &__card {
        max-width: 45%;
        width: 45%;

        &:nth-child(n) {
          margin-top: 20px;
        }
        &:not(:nth-last-child(-n+3)) {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 600px){
  .bc-specialists {
    &__content {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-around;
      margin-top: 0;

      &__card {
        max-width: 100%;
        width: 100%;

        &:nth-child(2n+1) {
          margin-top: 40px;
        }
        &:nth-child(n) {
          margin-top: 40px;
        }
      }
    }
  }
}
</style>
